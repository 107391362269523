import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image/withIEPolyfill'
import ButtonSmall from 'components/button-small'
import Spacer from 'components/spacer'
import NoImage from 'components/no-image'
import { graphql, Link } from 'gatsby'
import { clearfix, column } from 'styles'
import { formatPrice, isDefined } from 'utils'

const ProductGrid = ({ buttonText = 'Compare models ›', items, ...props }) => {
  return (
    <Grid {...props}>
      {items.map(item => (
        <Column key={item.title} buttonText={buttonText} {...item} />
      ))}
    </Grid>
  )
}

const Grid = styled.div`
  ${clearfix}
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
`

const Column = props => {
  return (
    <StyledColumn>
      <Card {...props} />
    </StyledColumn>
  )
}

const StyledColumn = styled.div`
  margin-bottom: var(--size-m);
  width: 100%;

  @media (min-width: 481px) {
    ${column('1/2', 16)}
  }

  @media (min-width: 768px) {
    ${column('1/3', 16)}
  }

  @media (min-width: 1024px) {
    ${column('1/4', 16)}
  }

  @media (min-width: 1366px) {
    ${column('1/6', 16)}
  }
`

const Card = ({
  buttonText,
  highlights,
  imageFluid,
  imageAlt = '',
  link,
  startingAtPrice,
  title,
}) => {
  return (
    <CardLink to={link}>
      {isDefined(imageFluid) ? (
        <Img
          fluid={{ ...imageFluid, aspectRatio: 8 / 5 }}
          objectFit='contain'
          alt={imageAlt || title}
        />
      ) : (
        <NoImage />
      )}
      <CardTitle className='card-title'>{title}</CardTitle>
      {startingAtPrice ? (
        <CardPrice>Starting at {formatPrice(startingAtPrice, { round: true })}</CardPrice>
      ) : null}
      {highlights ? (
        <>
          <Spacer size='s' variable={false} />
          <CardHighlights>
            {highlights.map(highlight => (
              <li key={highlight}>{highlight}</li>
            ))}
          </CardHighlights>
        </>
      ) : null}
      <BottomSpacer size='s' variable={false} />
      <CardButton>{buttonText}</CardButton>
    </CardLink>
  )
}

const CardLink = styled(Link)`
  background-color: #fff;
  border: 1px solid var(--color-n30);
  color: var(--color-n900);
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--size-s);
  text-decoration: none;

  :hover,
  :focus {
    .card-title {
      text-decoration: underline;
    }
  }
`

const CardTitle = styled.span`
  display: block;
  font-size: 1.125rem;
  font-weight: 700;
  margin-top: var(--size-m);
  text-align: center;
`

const CardPrice = styled.span`
  display: block;
  font-size: 1.125rem;
  font-weight: 400;
  margin-top: var(--size-xxs);
  text-align: center;
`

const CardHighlights = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid var(--color-n30);
    color: var(--color-n100);
    font-size: 1rem;
    margin: 0;
    padding: var(--size-xs) 0;
    text-align: center;

    :last-child {
      border-bottom: 1px solid var(--color-n30);
    }
  }
`

const BottomSpacer = styled(Spacer)`
  flex-grow: 2;
`

const CardButton = styled(ButtonSmall)`
  margin: 0 auto;
`

export const ProductGridImageFragments = graphql`
  fragment ProductGridImageSharp on File {
    childImageSharp {
      fluid(maxWidth: 350) {
        ...GatsbyImageSharpFluid
      }
    }
  }
  fragment ProductGridImageSanity on SanityImage {
    asset {
      fluid(maxWidth: 350) {
        ...GatsbySanityImageFluid
      }
    }
  }
`

export default ProductGrid
