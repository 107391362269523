import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

const ButtonSmall = ({ children, color, ghost, ...props }) => {
  return (
    <StyledButtonSmall color={color} ghost={ghost} {...props}>
      {children}
    </StyledButtonSmall>
  )
}

ButtonSmall.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['green', 'gray', 'yellow']),
  ghost: PropTypes.bool,
}

ButtonSmall.defaultProps = {
  color: 'green',
  ghost: false,
}

const StyledButtonSmall = styled.button`
  background-color: var(--color-y400);
  border: none;
  color: var(--color-y700);
  cursor: default;
  display: block;
  font-family: var(--font-family);
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.15;
  margin: 0;
  padding: var(--size-xs);
  overflow: visible;
  text-align: center;
  text-transform: none;
  transition: all 0.2s ease;
  width: auto;

  :disabled {
    opacity: 0.5;
  }

  :not(:disabled) {
    :hover,
    :active,
    :focus {
      background-color: var(--color-y300);
      cursor: pointer;
    }
  }
`

export default ButtonSmall
