import React from 'react'
import styled from 'styled-components'

const NoImage = ({ children = 'No Image Available', ...props }) => {
  return (
    <StyledNoImage {...props}>
      <small>{children}</small>
    </StyledNoImage>
  )
}

const StyledNoImage = styled.div`
  background-color: var(--color-n20);
  display: block;
  height: 0px;
  overflow: hidden;
  padding-bottom: 62.5%;
  position: relative;
  width: auto;

  small {
    color: var(--color-n400);
    display: block;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }
`

export default NoImage
