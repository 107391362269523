import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/layout'
import ScrollableContent from 'components/scrollable-content'
import ProductGrid from 'components/product-grid'
import Spacer from 'components/spacer'
import isDefined from 'utils/is-defined'

const SeriesTemplate = props => {
  const {
    data: {
      allSanityJohnDeereEquipment,
      sanityJohnDeereEquipmentSeries: { title },
    },
  } = props

  const [gridItems] = useState(() => {
    return allSanityJohnDeereEquipment.nodes.map(node => {
      const nodeFormatted = {
        imageFluid:
          Array.isArray(node.images) && node.images.length > 0 ? node.images[0].asset.fluid : null,
        link: node.configureSlug,
        title: node.title,
        highlights: node.highlights,
      }
      node.options.forEach(option => {
        const minPrice = Math.min(...[option.advertisedPrice, option.price].filter(isDefined))
        if (!nodeFormatted.startingAtPrice || minPrice < nodeFormatted.startingAtPrice) {
          nodeFormatted.startingAtPrice = minPrice
        }
      })
      return nodeFormatted
    })
  })
  return (
    <Layout
      seo={{
        title,
        description: `Build and price John Deere ${title} to meet your needs. Customize your build with options, attachments, implements, warranty, and financing and easily calculate the costs.`,
      }}
    >
      <ScrollableContent>
        <h2>{title}</h2>
        <Spacer size='m' />
        <ProductGrid items={gridItems} buttonText='Build ›' />
      </ScrollableContent>
    </Layout>
  )
}

export const pageQuery = graphql`
  query seriesTemplate($id: String!) {
    sanityJohnDeereEquipmentSeries(id: { eq: $id }) {
      title
    }
    allSanityJohnDeereEquipment(
      filter: {
        series: { id: { eq: $id } }
        published: { eq: true }
        configureEnabled: { eq: true }
      }
      sort: { fields: title, order: ASC }
    ) {
      nodes {
        configureSlug
        highlights
        images {
          ...ProductGridImageSanity
        }
        options {
          price
        }
        title
      }
    }
  }
`

export default SeriesTemplate
